/// <reference path="o365.pwa.declaration.sw.dexie.objectStores.ObjectStore.d.ts" />

// ********************** //
// **** Type imports **** //
// ********************** //
// region
import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { Index } from 'o365.pwa.declaration.sw.dexie.objectStores.Index.d.ts';
import type * as ObjectStoreModule from 'o365.pwa.declaration.sw.dexie.objectStores.ObjectStore.d.ts';
// endregion

// ********************** //
// **** Declarations **** //
// ********************** //
// region
declare var self: IO365ServiceWorkerGlobalScope;
// endregion

// ************** //
// **** IIFE **** //
// ************** //
// region
(() => {
    const indexedDBHandlerImportmapEntry = self.o365.getImportMapEntryFromImportUrl('o365.pwa.modules.sw.IndexedDBHandler.ts');

    class ObjectStore implements ObjectStoreModule.ObjectStore {
        static objectStoreDexieSchema: string = "&[appId+databaseId+id], [appId+databaseId], appId, databaseId, id";

        public id!: string;
        public appId!: string;
        public databaseId!: string;
        public jsonDataVersion!: number | null;

        public get indexes() {
            const objectStore = this;

            return new Proxy<ObjectStoreModule.Indexes>(<ObjectStoreModule.Indexes>{
                getAll: async () => {
                    const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

                    return await IndexedDBHandler.getIndexes(objectStore.appId, objectStore.databaseId, objectStore.id);
                }
            }, {
                get(target, prop, receiver) {
                    if (prop in target) {
                        return Reflect.get(target, prop, receiver);
                    }

                    return new Promise<Index | null>(async (resolve, reject) => {
                        try {
                            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

                            const index = await IndexedDBHandler.getIndex(objectStore.appId, objectStore.databaseId, objectStore.id, prop.toString());

                            resolve(index);
                        } catch (reason) {
                            reject(reason);
                        }
                    });
                }
            });
        }

        public get schema(): Promise<string> {
            return new Promise(async (resolve, reject) => {
                try {
                    const indexes = await this.indexes.getAll();

                    let objectStoreSchema = '';
                    let seperator = '';
                    let hasPrimaryKey = false;

                    for (const index of indexes) {
                        if (index.isPrimaryKey) {
                            objectStoreSchema = index.schema + seperator + objectStoreSchema;
                            hasPrimaryKey = true;
                        } else {
                            objectStoreSchema += seperator + index.schema;
                        }

                        seperator = ', ';
                    }

                    if (indexes.length === 0) {
                        objectStoreSchema = '++';
                    } else if (hasPrimaryKey === false) {
                        objectStoreSchema = '++, ' + objectStoreSchema;
                    }

                    resolve(objectStoreSchema);
                } catch (reason) {
                    reject(reason);
                }
            });
        }

        constructor(id: string, databaseId: string, appId: string, jsonDataVersion: number | null) {
            this.id = id;
            this.databaseId = databaseId;
            this.appId = appId;
            this.jsonDataVersion = jsonDataVersion;
        }

        public async save(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

            await IndexedDBHandler.updateObjectStore(this);
        }

        public async delete(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);
            
            await IndexedDBHandler.deleteObjectStore(this);
        }

        public async forceReload(): Promise<ObjectStore | null> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);
            
            return await IndexedDBHandler.getObjectStoreFromIndexedDB(this.appId, this.databaseId, this.id);
        }
    }

    self.o365.exportScripts({ ObjectStore });
})();
// endregion
